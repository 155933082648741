import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from './App';

import './style/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div className='root'>
      <React.StrictMode>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </React.StrictMode>
    </div>
);
